export const endpoint = {
  API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL || "https://api.johnsonbathrooms.in",
  // process.env.REACT_APP_API_BASE_URL || 'http://localhost:9000',
  // process.env.REACT_APP_API_BASE_URL || "https://apijohnson.clouddeploy.in",
  // BASE_URL_STAGING_GRAPHQL: 'https://staging.shopyou.com.au/graphql',
  // SERVER_URL: "http://johnsonbackend.clouddeploy.in/media/catalog/product",
  SERVER_URL: "https://backend.johnsonbathrooms.in/media/catalog/product",

  CATEGORY_TREE: "rest/V1/catalog/categories",
  CATALOGUE: "rest/V1/catalogue/list",
  CAMPAIGN: "rest/V1/campaign/list",
  PRODUCT_DETAIL: "rest/V1/catalog/products/",
  PRODUCT_LISTING: "rest/V1/category/products",
  PRODUCT_SEARCH: "rest/all/V1/products",
  BANNERS: "rest/V1/content/banner",
  NEW_ARRIVALS: "rest/V1/catalog/newarrivals",
  COUNTRY_STATE: "directory/countries",
  REGISTER: "rest/V1/customers",
  LOGIN: "rest/V1/integration/customer/token",
  CUSTOMER_DETAILS: "rest/V1/customers/me",
  MEDIA: "rest/V1/pressrelease/list",
  PRESS_RELEASE: "rest/V1/pressrelease/list",
  EVENTS: "rest/V1/event/list",
  NEWSLETTER: "rest/V1/newsletter/list",
  DEALERLIST: "rest/V1/dealer/list",
  TECHNICIAN: "rest/V1/technician/save_technician",
  HTML_PAGE: "rest/V1/webservice/get_cms_page_urlkey",
  STATE_LIST: "/rest/V1/dealer/state/list",
  REGION_STATE_LIST: "rest/V1/region_state_list_available",
  WISHLIST: "rest/V1/wishlist",
  SAVE: "rest/V1/Query/Save",
  BATHROOMCONCEPT: "/rest/V1/concepts/list",
  VIDEOS: "/rest/V1/media/list",
  BLOGS: "/rest/V1/webservice/get_blogs_list",
  GETBLOGS_ID: "/rest/V1/webservice/get_blogbyid",
  STATICHOMEPAGE: "/rest/V1/webservice/get_cms_staticblock",
  AWARDS: "/rest/V1/webservice/get_awards",
  GETAWARDSBY_ID: "/rest/V1/webservice/get_award",
  REGIONALS_NUMBERS: "/rest/V1/offices/list",
  CONTACT_US: "/rest/V1/contact/Save",
  ENQUIRY: "/rest/V1/enquiry/Save",
  TESTIMONIALS: "/rest/V1/testimonials/list",
  RQEQUEST_FOR_PRICE: "/rest/V1/requestSetPrice",
  SOLUTIONS_DOWNLOADS: "/rest/V1/downloadcatalogue/list",
  BLOGCATEGORIES: "/rest/V1/webservice/get_blog_categories",
  BLOGSLIST: "/rest/V1/webservice/get_category_blogs_url",
  BLOGSBYKEY: "rest/V1/webservice/get_blogbyurl_key",
  BATHROOMCONCEPTBYKEY: "rest/V1/concepts/byurlkey",
  CAMPAIGNFORMREQUEST: "rest/V1/campaign/save_campaign_request",
};
