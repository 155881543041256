import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Layout from "hocs/Layout";
import ScrollToTop from "component/ScrollToTop/ScrollToTop";
import { getHomepageData } from "api/homepage";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import { apiHandler } from "api";
import { endpoint } from "api/endpoint";
import { saveBanner, saveNewArrival } from "features/homepage/homeDataSlice";

import HomePage from "../src/container/HomePage/HomePage";
import CustomRoute from "CustomRoute";
const WebsiteCampaign = lazy(() =>
  import("container/WebsiteCampaign/WebsiteCampaign")
);
const WebsiteCampaignThanksYou = lazy(() =>
  import("container/WebsiteCampaign/WebsiteCampaignThanksYou")
);
// const HomePage = lazy(() =>
//   import("container/HomePage/HomePage")
// );
// import BathRoomConcept from 'container/BathroomConcepts/BathroomConcepts';
const BathRoomConcept = lazy(() =>
  import("container/BathroomConcepts/BathroomConcepts")
);
// import ExploreAwards from 'container/ExploreAwards/exploreawards';
const ExploreAwards = lazy(() =>
  import("container/ExploreAwards/exploreawards")
);
// import ProductCategories from 'container/ProductCategories/ProductCategories';
const ProductCategories = lazy(() =>
  import("container/ProductCategories/ProductCategories")
);
// import ProductDetail from 'container/ProductDetail/ProductDetail';
const ProductDetail = lazy(() =>
  import("container/ProductDetail/ProductDetail")
);
// import Disclaimer from 'container/Disclaimer/disclaimer';
const Disclaimer = lazy(() => import("container/Disclaimer/disclaimer"));
// import ExploreBlogs from 'container/ExploreBlogs/Exploreblogs';
const ExploreBlogs = lazy(() => import("container/ExploreBlogs/Exploreblogs"));
// import ExperienceCenter from 'container/ExperienceCenter/ExperienceCenter';
const ExperienceCenter = lazy(() =>
  import("container/ExperienceCenter/ExperienceCenter")
);
// import ProductListing from 'container/ProductListing/ProductListing';
const ProductListing = lazy(() =>
  import("container/ProductListing/ProductListing")
);
// import AboutUs from 'container/AboutUs/AboutUs';
const AboutUs = lazy(() => import("container/AboutUs/AboutUs"));
// import SignUp from 'container/Signup/Signup';
const SignUp = lazy(() => import("container/Signup/Signup"));
// import Termofuse from 'container/Termofuse/Termofuse';
const Termofuse = lazy(() => import("container/Termofuse/Termofuse"));
// import PrivacyPolicy from 'container/Privacypolicy/Privacypolicy';
const PrivacyPolicy = lazy(() =>
  import("container/Privacypolicy/Privacypolicy")
);
// import BathroomCost from 'container/BathroomCost/BathroomCost';
const BathroomCost = lazy(() => import("container/BathroomCost/BathroomCost"));
// import Catalogue from 'container/Catalogue/Catalogue';
const Catalogue = lazy(() => import("container/Catalogue/Catalogue"));
// import Campaigns from 'container/Campaigns/Campaigns';
const Campaigns = lazy(() => import("container/Campaigns/Campaigns"));
// import PressRelease from 'container/PressRelease/PressRelease';
const PressRelease = lazy(() => import("container/PressRelease/PressRelease"));
// import NewsLetter from 'container/NewsLetter/NewsLetter';
const NewsLetter = lazy(() => import("container/NewsLetter/NewsLetter"));
// import EventsPage from 'container/EventsPage/Eventspage';
const EventsPage = lazy(() => import("container/EventsPage/Eventspage"));
// import Videos from 'container/Videos/Videos';
const Videos = lazy(() => import("container/Videos/Videos"));
// import Faqs from 'container/FAQs/Faqs';
const Faqs = lazy(() => import("container/FAQs/Faqs"));
// import SearchProducts from '../src/container/SearchProducts/SearchProduct';
const SearchProducts = lazy(() =>
  import("../src/container/SearchProducts/SearchProduct")
);
// import ExploreBathroomConcept from 'container/ExploreBathroomConcept/ExploreConcept';
const ExploreBathroomConcept = lazy(() =>
  import("container/ExploreBathroomConcept/ExploreConcept")
);
// import DealerLocator from 'container/DealerLocator/DealerLocator';
const DealerLocator = lazy(() =>
  import("container/DealerLocator/DealerLocator")
);
// import Enquiry from 'container/Enquiry/enquiry';
const Enquiry = lazy(() => import("container/Enquiry/enquiry"));
// import Testimonial from 'container/Testimonials/Testimonial';
const Testimonial = lazy(() => import("container/Testimonials/Testimonial"));
// import CorporateOffice from 'container/CorporateOffice/CorporateOffice';
const CorporateOffice = lazy(() =>
  import("container/CorporateOffice/CorporateOffice")
);
// import Registeredoffice from 'container/RegisteredOffice/RegisteredOffice';
const Registeredoffice = lazy(() =>
  import("container/RegisteredOffice/RegisteredOffice")
);
// import Technician from 'container/Technician/Technician';
const Technician = lazy(() => import("container/Technician/Technician"));
// import Solutions from 'container/Solutions/Solutions';
const Solutions = lazy(() => import("container/Solutions/Solutions"));
// import International from 'container/International/International';
const International = lazy(() =>
  import("container/International/International")
);
// import Login from 'container/Login/Login';
const Login = lazy(() => import("container/Login/Login"));
// import WishlistPage from 'container/WishlistPage/WishlistPage';
const WishlistPage = lazy(() => import("container/WishlistPage/WishlistPage"));
// import Awards from 'container/Awards/Awards';
const Awards = lazy(() => import("container/Awards/Awards"));
// import ErrorPage from 'container/errorPage/ErrorPage';
const ErrorPage = lazy(() => import("container/errorPage/ErrorPage"));
// import RedirectPage from 'container/RedirectComponent/RedirectPage';
const RedirectPage = lazy(() =>
  import("container/RedirectComponent/RedirectPage")
);
// import ThankYou from 'container/ThankYou/ThankYou';
const ThankYou = lazy(() => import("container/ThankYou/ThankYou"));
// import ThankYouWishlist from 'container/ThankYouWishlist/ThankYouWishlist';
const ThankYouWishlist = lazy(() =>
  import("container/ThankYouWishlist/ThankYouWishlist")
);
// import Blogcategories from 'container/BlogCategories/blogcategories';
const Blogcategories = lazy(() =>
  import("container/BlogCategories/blogcategories")
);

toast.configure({
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
});

const App = () => {
  const { categories, newArrivals, banner } = useSelector(
    (state) => state.home
  );
  const dispatch = useDispatch();
  const { pathname } = window.location;

  const loadTawkScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerText = `var Tawk_API = Tawk_API || {},Tawk_LoadStart = new Date(); !(function () { var e = document.createElement("script"),t = document.getElementsByTagName("script")[0];(e.async = !0),(e.src = "https://embed.tawk.to/611364ef649e0a0a5cd08f7b/1fcps4j1f"),(e.charset = "UTF-8"),e.setAttribute("crossorigin", "*"),t.parentNode.insertBefore(e, t);})();`;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const loadGtmTag = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerText = `!(function (e, t, a, n, g) {(e[n] = e[n] || []), e[n].push({ "gtm.start": new Date().getTime(), event: "gtm.js" }); var m = t.getElementsByTagName(a)[0], r = t.createElement(a); (r.defer = !0), (r.src = "https://www.googletagmanager.com/gtm.js?id=GTM-W7TD298"), m.parentNode.insertBefore(r, m); })(window, document, "script", "dataLayer");`;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    if (pathname === "/") {
      if (!newArrivals.length) {
        apiHandler({
          url: endpoint.NEW_ARRIVALS,
        }).then((newArrivals) => {
          if (!newArrivals.data.error_code)
            dispatch(saveNewArrival(newArrivals.data));
        });
      }
    }

    if (pathname === "/" || pathname === "/international") {
      if (!banner.length) {
        apiHandler({
          url: endpoint.BANNERS,
        }).then((banners) => {
          if (!banners.data.error_code) dispatch(saveBanner(banners.data));
        });
      }
    }
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      loadGtmTag();
    }, 3000);
    // console.log('......Function called before exactdispatch');
    if (categories.length > 0) {
      // console.log('......Data already exists');
      return;
    } else {
      getHomepageData({ dispatch }).then((result) => {
        // console.log('DATA - ', result);
        // forceUpdate();
        // window.location.reload();
      });
    }

    if (pathname !== "/campaign") {
      setTimeout(() => {
        loadTawkScript();
      }, 8000);
    }
  }, [pathname]);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Suspense
          fallback={
            <div className="loading-overlay">
              <div className="bounce-loader">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          }
        >
          <Switch>
            <CustomRoute exact path="/">
              <HomePage />
            </CustomRoute>
            <CustomRoute exact path="/campaign">
              <WebsiteCampaign />
            </CustomRoute>
            <CustomRoute exact path="/campaign/thank-you">
              <WebsiteCampaignThanksYou />
            </CustomRoute>
            <CustomRoute exact path="/bathroom-concepts">
              <BathRoomConcept />
            </CustomRoute>
            <CustomRoute exact path="/bathroom-concepts/:id">
              <ExploreBathroomConcept />
            </CustomRoute>
            <CustomRoute exact path="/blogs/:id">
              <ExploreBlogs />
            </CustomRoute>
            <CustomRoute exact path="/award/:id">
              <ExploreAwards />
            </CustomRoute>
            <CustomRoute exact path="/about-us">
              <AboutUs />
            </CustomRoute>
            <CustomRoute exact path="/products/:category">
              <ProductCategories />
            </CustomRoute>
            <CustomRoute exact path="/search-products/:filter">
              <SearchProducts />
            </CustomRoute>
            <CustomRoute exact path="/products/:category/:type">
              <ProductListing />
            </CustomRoute>
            <CustomRoute exact path="/product-detail/:url_key">
              <ProductDetail />
            </CustomRoute>
            <CustomRoute exact path="/sign-up">
              <SignUp />
            </CustomRoute>
            <CustomRoute exact path="/termofuse">
              <Termofuse />
            </CustomRoute>
            <CustomRoute exact path="/disclaimer">
              <Disclaimer />
            </CustomRoute>
            <CustomRoute exact path="/privacy/policy">
              <PrivacyPolicy />
            </CustomRoute>
            <CustomRoute exact path="/bathroom-cost">
              <BathroomCost />
            </CustomRoute>
            <CustomRoute exact path="/catalogue">
              <Catalogue />
            </CustomRoute>
            <CustomRoute exact path="/ads_articles">
              <Campaigns />
            </CustomRoute>
            <CustomRoute exact path="/press-release">
              <PressRelease />
            </CustomRoute>
            <CustomRoute exact path="/media/newsletter">
              <NewsLetter />
            </CustomRoute>
            <CustomRoute exact path="/events">
              <EventsPage />
            </CustomRoute>
            <CustomRoute exact path="/blogs">
              <Blogcategories />
            </CustomRoute>
            {/* <CustomRoute exact path='/blogs'> <Blogs/></CustomRoute/>*/}
            <CustomRoute exact path="/locate/dealer">
              <DealerLocator />
            </CustomRoute>
            <CustomRoute exact path="/product-video">
              <Videos />
            </CustomRoute>
            <CustomRoute exact path="/enquiry">
              <Enquiry />
            </CustomRoute>
            <CustomRoute exact path="/faqs">
              <Faqs />
            </CustomRoute>
            <CustomRoute exact path="/locate/registered-office">
              <Registeredoffice />
            </CustomRoute>
            <CustomRoute exact path="/locate/contact-us">
              <CorporateOffice />
            </CustomRoute>
            <CustomRoute exact path="/thank-you">
              <ThankYou />
            </CustomRoute>
            <CustomRoute exact path="/thankyou">
              <ThankYouWishlist />
            </CustomRoute>
            <CustomRoute exact path="/testimonials">
              <Testimonial />
            </CustomRoute>
            <CustomRoute exact path="/locate/technician">
              <Technician />
            </CustomRoute>
            <CustomRoute exact path="/experience-center">
              <ExperienceCenter />
            </CustomRoute>
            <CustomRoute exact path="/solutions">
              <Solutions />
            </CustomRoute>
            <CustomRoute exact path="/international">
              <International />
            </CustomRoute>
            <CustomRoute exact path="/login">
              <Login />
            </CustomRoute>
            <CustomRoute exact path="/wishlist">
              <WishlistPage />
            </CustomRoute>
            <CustomRoute exact path="/awards">
              <Awards />
            </CustomRoute>
            <CustomRoute exact path="/products-inner.php">
              <RedirectPage />
            </CustomRoute>
            <CustomRoute path="*">
              <ErrorPage />
            </CustomRoute>
          </Switch>
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
